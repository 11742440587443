import { BlogDetail, BlogPage, BlogPage2 } from "../pages/BlogPages";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HomePage, HomePage2 } from "../pages/HomePages";
import {
  PortfolioDetail,
  PortfolioPage,
  PortfolioPage2,
  PortfolioPage3,
} from "../pages/PortfolioPages";
import { ServiceDetail, ServicePage } from "../pages/ServicePages";

import { ContactPage } from "../pages/ContactPage";
import { ErrorPage } from "../pages/ErrorPage";
import Photo from "../components/Photo/Photo";
import { PlansPage } from "../pages/PlansPage";
import SmoothScroll from "../components/SmoothScroll/SmoothScroll";
import VideoSection from "../components/Video/VideoSection";

const RouterLinks = () => {
  return (
    <>
      <BrowserRouter>
        <SmoothScroll>
          <Routes>
            <Route path="/" element={<HomePage2></HomePage2>} />
            <Route path="/video" element={<VideoSection></VideoSection>} />
            <Route path="/photo" element={<Photo></Photo>} />
            <Route path="/contact" element={<ContactPage></ContactPage>} />
            <Route path="/404" element={<ErrorPage></ErrorPage>} />
            <Route path="*" element={<ErrorPage></ErrorPage>} />
          </Routes>
        </SmoothScroll>
      </BrowserRouter>
    </>
  );
};

export default RouterLinks;
