import { ReactComponent as DesktopApplicationIcon } from "../lib/icons/InformationService.svg";
import { ReactComponent as StrategyIcon } from "../lib/icons/Strategy.svg";
import { ReactComponent as TranslationIcon } from "../lib/icons/Translation.svg";
import { ReactComponent as UiCreativeDesignIcon } from "../lib/icons/Curve.svg";
import { ReactComponent as WebDevelopmentIcon } from "../lib/icons/Monitor.svg";
import { ReactComponent as WireframingServiceIcon } from "../lib/icons/WebProgramming.svg";

export const services = [
  {
    id: 1,
    bgColor: "bg-accent",
    titleColor: "text-white",
    descColor: "text-white",
    servIcon: (
      <UiCreativeDesignIcon className="h-[4.25rem] w-[4.25rem] fill-white"></UiCreativeDesignIcon>
    ),
    servTitle: "Actor",
    servDesc:
      "Energetic and versatile actor with experience in both professional and amateur settings. Skilled in delivering engaging performances across various media formats. Strong communication abilities, combined with a proactive approach to collaboration, ensure effective teamwork and impactful storytelling in diverse creative projects.",
  },
  {
    id: 2,
    bgColor: "bg-[#f0e1d4]",
    titleColor: "text-accent2",
    descColor: "text-desc2",
    servIcon: (
      <WebDevelopmentIcon className="h-[4.25rem] w-[4.25rem] fill-accent"></WebDevelopmentIcon>
    ),
    servTitle: "Flim Producer",
    servDesc:
      "Highly skilled Producer with over 7 years of experience managing production processes, coordinating teams, and delivering high-quality content. Proven expertise in driving project timelines, budgeting, and creative problem-solving, ensuring smooth execution and successful delivery of complex projects across various media platforms.",
  },
  {
    id: 3,
    bgColor: "bg-[#d4e4f0]",
    titleColor: "text-accent2",
    descColor: "text-desc2",
    servIcon: (
      <WireframingServiceIcon className="h-[4.25rem] w-[4.25rem] fill-accent"></WireframingServiceIcon>
    ),
    servTitle: "Social Worker",
    servDesc:
      "Dedicated social worker and program coordinator focused on supporting disadvantaged youth and adults, including homeless, emancipated youth, and special-needs populations. Committed to providing vital resources, fostering positive change, and empowering individuals to overcome challenges and improve their quality of life.",
  },
];
